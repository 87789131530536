<template>
    <div class="container-blocked">
        <div class="side-bar-blocked">
            <img crossorigin="anonymous" :src="this.$logo" alt="" class="mb-3">
            <div class="my-3 side-personal-blocked">
                <span class="name-user-blocked">{{ name }}</span>
                <span class="state-user-blocked">Personal</span>
            </div>
            <div class="pt-3 side-items-blocked">
                <img src="@/assets/images/icon/fi-rr-archive.svg" alt="" class="mr-3 side-items-img-blocked">
                <span>My Files</span>
            </div>
            <div class="pt-3 side-items-blocked">
                <img src="@/assets/images/icon/fi-rr-time-half-past.svg" alt="" class="mr-3 side-items-img-blocked">
                <span>Recents</span>
            </div>
            <div class="pt-3 side-items-blocked">
                <img src="@/assets/images/icon/fi-rr-shuffle.svg" alt="" class="mr-3 side-items-img-blocked">
                <span>Shared</span>
            </div>
            <div class="pt-3 side-items-blocked">
                <img src="@/assets/images/icon/fi-rr-trash.svg" alt="" class="mr-3 side-items-img-blocked">
                <span>Trash</span>
            </div>
            <div class="footer-blocked">
                <b-progress :value="originalUsed" :max="originalSize"  variant="primary"></b-progress>
                <span class="value-size-blocked">{{ niceBytes(originalUsed) }} of {{ niceBytes(originalSize) }} used.</span>
                <span class="upgrade-info-blocked">Upgrade Storage</span>
            </div>
        </div>
        <div class="main-blocked">
            <div class="navbar-blocked">
                <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="search-box-blocked">
                        <input type="search" class="form-control" placeholder="Search" />
                        <img src="@/assets/images/icon/search-black.svg" alt="">
                    </div>
                    <div class="d-flex justify-content-start align-items-center">
                        <img src="@/assets/images/icon/icons-lonceng.svg" alt="" class="mr-3">
                        <b-dropdown id="dropdown-dropup" dropup right class="bg-transparent profile-dropdown">
                            <template #button-content>
                                <!-- <div @click="toggleSwitchShow" class="avatar-xs"> -->
                                <div class="avatar-xs">
                                    <span class="avatar-title rounded-circle">A</span>
                                </div>
                            </template>
                            <b-dropdown-header id="dropdown-header-label">
                                <div class="d-flex">
                                    <b-avatar variant="primary" :text="name.charAt(0).toUpperCase()" class="mr-2 avatar-xs"></b-avatar>
                                    <div class="d-flex justify-content-start align-items-center">
                                        <span class="user-name default-text-style-profile "><span class="letter05">{{ name }}</span></span>
                                    </div>
                                </div>
                            </b-dropdown-header>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item href="/logout">
                                <img src="@/assets/images/icon/logout.svg" alt height="100%" />
                                <span class="ml-3 default-text-style-profile">Sign Out</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <div class="content-blocked">   
                <img src="@/assets/images/blocked-img.svg" alt="" height="128px">
                <span class="title-blocked mt-3">Your account is locked</span>
                <span class="sub-title-blocked mb-3">Please update your payment method or renew your plan so you can organize, share, and secure your files again. </span>
                <button class="btn-pay-blocked">Update Payment</button>
            </div>
        </div>
    </div>
</template>

<script>

export default ({
    data() {
        return {
            originalUsed: 873741824,
            originalSize: 5368709120,
            name: 'abcdefghijklmnopqrstuvwxyz'
        }
    },
    methods: {
        niceBytes(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return 'n/a';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            if (i == 0) return bytes + ' ' + sizes[i];
            return (bytes / Math.pow(1024, i)).toFixed() + ' ' + sizes[i];
        }
    }
})
</script>


<style scoped>
.container-blocked{
    width: 100vw;
    height: 100vh;
    display: flex;
}
.side-bar-blocked{
    width: 216px;
    background: #F6F6FC;
    height: 100vh;
    padding: 24px;
}
.side-personal-blocked{
    padding: 8px 16px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
}
.name-user-blocked{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -1px;
    color: #262A2C;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.state-user-blocked{
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: -0.5px;
    color: #919394;
}
.side-bar-blocked img{
    margin-top: 8px;
    width: 101px;
    height: 25px;
}
.side-items-blocked img{
    width: 16px !important;
    height: 16px !important;
    margin: 0 0 0 8px;
}
.side-items-blocked span{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -1px;
    color: #262A2C;
}
.main-blocked{
    width: calc(100vw - 216px);
    height: 100vh;
}
.navbar-blocked{
    position: relative;
    height: 90px;
    border-bottom: 1px solid #E8E8E8;
    display: flex;
    max-width: calc(100vw - 216px);
    align-items: center;
    padding: 0 24px;
}
.search-box-blocked input{
    width: 300px;
    height: 40px;
    padding-left: 40px;
    border: 1px solid #E9EAEA;
}
.search-box-blocked input:focus{
    border: 1px solid #00AAFF;
}
.search-box-blocked input::placeholder{
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
    font-weight: 300;
    color: #919394;
}
.search-box-blocked img{
    position: absolute;
    top: 36px;
    left: 38px;
}
.content-blocked{
    position: relative;
    height: calc(100vh - 90px);
    padding: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.title-blocked{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #262A2C;
    margin-bottom: 8px;
}
.sub-title-blocked{
    font-size: 16px;
    line-height: 20px;
    color: #919394;
    font-weight: 300;
    letter-spacing: -1px;
}
.btn-pay-blocked{
    color: #FFFFFF;
    background: #00AAFF;
    border-radius: 4px;
    border: none;
    padding: 8px 16px;
    height: 40px;
    width: 154px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -1px;
}
.footer-blocked{
    position: absolute;
    bottom: 24px;
    display: flex;
    flex-direction: column;
}
.value-size-blocked{
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: -0.5px;
    color: #919394;
    margin-top: 8px;
}
.upgrade-info-blocked{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -1px;
    color: #E9EAEA;
}
</style>